import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer2 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.leczenieNadpotliwosci}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Leczenie nadpotliwości
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="leczenie nadpotliwości ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Czym jest nadmierna potliwość?</Subtitle>
            <Paragraph>
              <b>Nadpotliwość</b> to powszechny problem i dyskomfort, który w
              równym stopniu dotyczy kobiet i mężczyzn. Objawy są spowodowane
              nadczynnością gruczołów endokrynnych odpowiadających za
              wydzielanie potu w obrębie różnych części ciała. Nadpotliwość to
              przewlekłe zaburzenie objawiające się nadmierną produkcją potu,
              przekraczającą wydzielanie fizjologiczne (prawidłowe), jakie jest
              niezbędne do utrzymania termicznej homeostazy organizmu.
              Dolegliwości często obniżają samoocenę pacjenta i powodują jego
              wycofanie z życia społecznego. Leczenie nadmiernej potliwości daje
              znaczny wzrost komfortu życia.
            </Paragraph>
            <Subtitle>Wskazania do zabiegu i przyczyny nadpotliwości</Subtitle>
            <Paragraph noMarginBottom>
              Zabieg leczenia nadpotliwości jest zalecany osobom, które
              doświadczają nadmiernej potliwości przekraczającej normalne
              potrzeby termoregulacji ciała. To stan, który może znacząco
              wpłynąć na codzienne funkcjonowanie i jakość życia. Wskazania do
              zabiegu obejmują:
            </Paragraph>
            <List>
              <ListItem>nadmierna potliwość pach</ListItem>
              <ListItem>nadmierna potliwość dłoni</ListItem>
              <ListItem>nadmierna potliwość stóp</ListItem>
            </List>
            <Subtitle>Przyczyny nadpotliwości</Subtitle>
            <Paragraph noMarginBottom>
              <b>Nadpotliwość pierwotna</b> (hyperhidrosis primaria):
            </Paragraph>
            <List>
              <ListItem>
                <b>Genetyka:</b> Uwarunkowania genetyczne odgrywają znaczącą
                rolę. Jeśli w rodzinie występowały podobne przypadki, ryzyko
                jest wyższe.
              </ListItem>
              <ListItem>
                <b>Początek w okresie dojrzewania:</b> Najczęściej rozpoczyna
                się w okresie dojrzewania, ale może również pojawić się w każdym
                innym okresie życia.
              </ListItem>
              <ListItem>
                <b>Nieznana przyczyna:</b> Mimo że uwarunkowania genetyczne są
                istotne, dokładna przyczyna nadpotliwości pierwotnej często
                pozostaje nieznana.
              </ListItem>
            </List>
            <Paragraph noMarginBottom>
              <b>Nadpotliwość wtórna</b> (hyperhidrosis secundaria):
            </Paragraph>
            <List>
              <ListItem>
                <b>Stany chorobowe:</b> Choroby takie jak nadczynność tarczycy,
                cukrzyca, menopauza, otyłość, zespół niespokojnych nóg czy
                choroby serca mogą prowadzić do nadpotliwości.
              </ListItem>
              <ListItem>
                <b>Leki:</b> Pewne leki, w tym antydepresanty, leki na
                nadciśnienie oraz leki stosowane w leczeniu cukrzycy, mogą mieć
                nadpotliwość jako efekt uboczny.
              </ListItem>
              <ListItem>
                <b>Infekcje:</b> Niektóre infekcje, szczególnie te, które
                wpływają na układ nerwowy lub wywołują gorączkę, mogą
                przyczyniać się do nadpotliwości.
              </ListItem>
              <ListItem>
                <b>Zaburzenia hormonalne:</b> Zmiany poziomu hormonów,
                szczególnie w okresie menopauzy u kobiet, mogą prowadzić do
                nadmiernego pocenia się.
              </ListItem>
            </List>
            <Paragraph>
              Podsumowując, rozpoznanie i zrozumienie przyczyn nadpotliwości są
              kluczowe dla wyboru odpowiedniej metody leczenia. W zależności od
              rodzaju i przyczyny nadpotliwości, dostępne są różne opcje
              leczenia, w tym zastosowanie preparatów antyperspiracyjnych,
              terapia jontoforezą, leczenie botoksem, a w niektórych przypadkach
              interwencje chirurgiczne. Każdy przypadek wymaga indywidualnej
              oceny, aby zapewnić najbardziej skuteczne i bezpieczne leczenie.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu redukującego nadmierne pocenie</Subtitle>
            <Paragraph>
              Rozpoczęcie leczenia nadmiernego pocenia u naszych pacjentów
              zawsze poprzedzone jest <b>konsultacją lekarską</b>, które
              potwierdzi wskazania do zabiegu i{' '}
              <b>zakwalifikuje pacjenta do leczenia</b>. Zabieg leczenia
              nadpotliwości polega na wstrzyknięciu w kilkanaście punktów bardzo
              cienką igłą preparatu, który hamuje uwalnianie acetylocholiny,
              neuroprzekaźnika odpowiadającego za przekaźnictwo
              nerwowo-mięśniowe.
            </Paragraph>
            <Paragraph>
              W efekcie po wstrzyknięciu do skóry{' '}
              <b>niewielkiej dawki preparatu</b> dochodzi do zablokowania
              przekaźnictwa z nerwów zaopatrujących gruczoły wydzielania
              zewnętrznego. To zapobiega wydzielaniu przez nie potu.
            </Paragraph>
            <Paragraph>
              Wielu pacjentów porównuje ból do kilkusekundowego ugryzienia przez
              komara. Miejsce iniekcji znieczula się miejscowo specjalnym kremem
              znieczulającym.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty</Subtitle>
            <Paragraph>
              Pełne efekty zabiegu redukujące nadmierną potliwość dłoni, pach
              czy stóp pojawiają się po 7-14 dniach i utrzymują się do ok. 5-6
              miesięcy. Zabieg można powtórzyć po ok. 4 miesiącach co pozwala
              nam na wydłużenie okresu bez objawów.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Cena</Subtitle>
            <Paragraph>
              Cena pojedynczego zabiegu to 1 750 zł. Zapraszamy do rezerwacji.
              Przy pierwszej wizycie odbierz swoją kartę lojalnościową i
              otrzymaj -30% na czwarty zabieg medycyny estetycznej wykonany w
              Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania do leczenia nadpotliwości</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>
                Choroby nerwowo-mięśniowe t.j. miastenia gravis, Zespół
                Lamberta-Eatona
              </ListItem>
              <ListItem>
                Stosowanie bądź odstawienie w przeciągu tygodnia poprzedzającego
                zabieg leków takich, jak: D-penicylamina, cyklosporyna,
                tubokuraryna, pankuronium, galamina, sukcynylocholina,
                linkomycyna, tetracyklina, polimyksyna, aminochinolony,
                aminoglikozydy
              </ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do IDEALE Medycyna Estetyczna Warszawa Wola, gdzie
              odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer2;

export const Head = () => <SEO tags={TAGS.leczenieNapotliwosci} />;
